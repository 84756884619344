<template>
  <div class="card p-1">
    <div class="custom-search">
      <div class="title head">
        <p class="h2" style="color: #558cef">รายงานแยกตามกลุ่มทรัพย์สิน</p>
      </div>
      <hr />
      <!-- {{this.inspector}} -->
      <!-- advance search input -->
      <div class="custom-search row justify-content-start pl-1">
        <!-- <div>
          <label class="mr-1">จากปีงบประมาณ</label>
          <b-form-select v-model="selected" :options="options" />
        </div> -->
        <b-form-group class="col-12 col-md-4">
          <div class="align-items-center">
            <label class="mr-1">จากวันที่</label>
            <v-date-picker id="example-datepicker3" label="จากวันที่:" locale="th-TH" v-model="date1"
              @update:from-page="getHeaderInputDate">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input :value="formatDate(inputValue, 'date1')" v-on="inputEvents" />
              </template>
              <template #header-title>
                {{ headerInputDate }}
              </template>
            </v-date-picker>
          </div>
        </b-form-group>
        <b-form-group class="col-12 col-md-4">
          <div class="align-items-center">
            <label class="mr-1">ถึงวันที่</label>

            <v-date-picker id="example-datepicker3" label="ถึงวันที่:" locale="th-TH" v-model="date2"
              @update:from-page="getHeaderInputDate">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input :value="formatDate(inputValue, 'date2')" v-on="inputEvents" />
              </template>
              <template #header-title>
                {{ headerInputDate }}
              </template>
            </v-date-picker>
          </div>
        </b-form-group>
        <b-form-group class="col-12 col-md-4">
          <label>กลุ่มทรัพย์สิน:</label>

          <v-select v-model="equipmentGroup" :options="equipmentGroups" label="e_gid_e_gname"
            placeholder="เลือกกลุ่มทรัพย์สิน" />
        </b-form-group>
      </div>
      <div class="row justify-content-between">
        <div class="row col-6 mb-1">
          <div class="ml-1">
            <b-button variant="success" @click="get_table_report"> ค้นหา </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="danger" @click="cc_report"> ยกเลิก </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="outline-primary" @click="exportExcel"> Export Excel </b-button>
          </div>
        </div>
        <div class="mr-2">
          <b-overlay :show="load_pdf" rounded="sm">
            <b-button variant="outline-primary" @click="exportpdf"> พิมพ์รายงานแยกตามกลุ่มทรัพย์สิน PDF </b-button>
          </b-overlay>
        </div>
      </div>
    </div>

    <!-- table -->
    <b-overlay :show="show" rounded="sm">
      <vue-good-table :columns="columns" :rows="rows" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :pagination-options="{
  enabled: true,
  perPage: pageLength,
}" theme="my-theme" :line-numbers="true">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span>{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'listmenu'">
            <span>
              <b-button variant="outline-warning" :to="{ name: 'inseakuruplun', params: { id: props.row } }">
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </span>
          </span>
          <span v-else-if="props.column.field === 'จำนวน'">
            <div class="text-right">{{ props.row.จำนวน }}</div>
          </span>
          <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
            <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <div v-if="props.row.status === 'รับเข้า'">
              <b-button variant="info">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'เบิก'">
              <b-button variant="warning">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'ยืม'">
              <b-button variant="danger">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'คืน'">
              <b-button variant="success">
                {{ props.row.status }}
              </b-button>
            </div>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="pageLength" :options="['5', '10', '15', '25', '50', '100']" class="mx-1"
                @input="(value) => perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap">of {{ total }} entries</span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="total" :per-page="pageLength" first-number last-number align="right"
                prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BPagination, BFormGroup, BFormSelect, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import pdfMake from "pdfmake";
import API from "@/views/connectDB/condb";
import pdfFonts from "../font/custom-fonts";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,

    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  data() {
    return {
      date1: new Date(),
      date2: new Date(),
      date: {
        startDate: "",
        endDate: "",
      },
      selected: null,
      options: [{ value: null, text: "เลือกปีงบประมาณ" }],
      equipment_code: "",
      e_number: "",
      serial_number: "",
      e_name: "",
      load_pdf: false,
      currentPage: 1,
      pageLength: 100,
      total: "",
      show: false,
      dir: false,
      workgroup: null,
      workgroups: [],
      equipmentGroup: null,
      equipmentGroups: [],
      columns: [
        {
          label: "วันที่-เดือน-ปี",
          field: "วันที่รับเข้า",
        },
        {
          label: "เลขทะเบียน",
          field: "เลขทะเบียน",
        },
        {
          label: "รหัสทรัพย์สิน",
          field: "หมายเลขทรัพย์สิน",
        },

        {
          label: "ชื่อทรัพย์สิน",
          field: "ชื่อทรัพย์สิน",
        },
        {
          label: "รายการ รายละเอียด",
          field: "รายละเอียด",
        },
        {
          label: "หน่วยนับ",
          field: "หน่วยนับ",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "ราคาต่อหน่วย",
          formatFn: this.formatFn,
        },
        {
          label: "จำนวน",
          field: "จำนวน",
        },
        {
          label: "มูลค่ารวม",
          field: "มูลค่ารวม",
          formatFn: this.formatFn,
        },
        {
          label: "อายุการใช้งาน",
          field: "อายุการใช้งาน",
          formatFn: this.formatFn,
        },
        {
          label: "อัตราค่าเสื่อมราคา",
          field: "อัตราค่าเสื่อมราคาต่อปี",
          formatFn: this.formatFn,
        },
        {
          label: "ค่าเสื่อมประจำปี",
          field: "ค่าเสื่อมราคาประจำปี",
          formatFn: this.formatFn,
        },
        {
          label: "ค่าเสื่อมราคาสะสม",
          field: "ค่าเสื่อมราคาสะสม",
          formatFn: this.formatFn,
        },
        {
          label: "มูลค่าสุทธิ",
          field: "มูลค่าสุทธิ",
          formatFn: this.formatFn,
        },
        {
          label: "ใช้ได้",
          field: "ใช้ได้",
        },
        {
          label: "ใช้ไม่ได้",
          field: "ใช้ไม่ได้",
        },
        {
          label: "ซ่อม",
          field: "ซ่อม",
        },
        {
          label: "จำหน่าย",
          field: "จำหน่าย",
        },
        {
          label: "สถานที่เก่า/ผู้รับผิดชอบ",
          field: "สถานที่เก่า",
        },
        {
          label: "สถานที่ใหม่/ผู้รับผิดชอบ",
          field: "สถานที่ใหม่",
        },
        // {
        //   label: 'รายละเอียด',
        //   field: 'listmenu',
        //   width: '7%',
        // },
      ],
      rows: [],
      searchTerm: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],

      inspector: {},
      arr_sums: [],
    };
  },
  computed: {
    example: {
      cache: false,
      get() {
        return Date.now() + this.msg;
      },
    },
  },
  created() {
    // this.checkLogout();
    const y = new Date().getFullYear();
    const yy = y + 544;
    this.options.push({ value: yy, text: yy });
    for (let index = 1; index < 20; index++) {
      this.options.push({ value: yy - index, text: yy - index });
    }
    this.workgroups_table();
    this.equipment_table();
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },
    async inspectorForReport() {
      const { access_token } = await this.access_token();
      const url = `${API}inspectorForReport?e_number=${this.e_number}`;
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      const dataApi = res.data.message.data;
      console.log(res.data.message);
      this.inspector = dataApi.map((res) => ({
        inspector1: res.inspector1 || "",
        inspector2: res.inspector2 || "",
        inspector3: res.inspector3 || "",
        inspector_pos1: res.inspector_pos1 || "",
        inspector_pos2: res.inspector_pos2 || "",
        inspector_pos3: res.inspector_pos3 || "",
      }));
      console.log(this.inspector);
    },

    async exportpdf() {
      if (this.equipmentGroup === null) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `กรุณาเลือกกลุ่มทรัพย์สิน`,
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      // if (this.selected === null) {
      //   Swal.fire({
      //     position: "center",
      //     icon: "error",
      //     title: `กรุณาเลือกปีงบประมาณ`,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   return;
      // }
      this.load_pdf = true;
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      // const startDate = `${this.selected - 544}-10-01`;
      // const endDate = `${this.selected - 543}-09-30`;

      const url1 = `${API}calculateAnnualDepreciation?startDate=${this.date.startDate}&endDate=${this.date.endDate}&e_gid=${this.equipmentGroup.e_gid}&_page=${this.currentPage}&_limit=${this.pageLength}`;

      const data = await axios.get(url1, header);
      const { dataLast, total } = data.data.message;
      this.e_number = await dataLast[0].หมายเลขทรัพย์สิน;
      let idx = Math.ceil(Number(total) / 100);

      let res_pdf = [];
      for (let index = 1; index <= idx; index++) {
        console.log(index);
        const url_pdf = `${API}calculateAnnualDepreciation?startDate=${this.date.startDate}&endDate=${this.date.endDate}&e_gid=${this.equipmentGroup.e_gid}&_page=${index}&_limit=100`;
        const res_pdfs = await axios.get(url_pdf, header);

        
        const { dataLast, sum } = res_pdfs.data.message;
        console.log(sum);
        // const add_data = [{ ชื่อทรัพย์สิน: "รวมเป็นเงินทั้งสิน" }];
        // sum[(30).Object.keys(30)].push({
        //   ชื่อทรัพย์สิน: "รวมเป็นเงินทั้งสิน"
        // })
        sum["ชื่อทรัพย์สิน"] = "รวมเป็นเงินทั้งสิ้น";
        sum["ราคาต่อหน่วย"] = " ";
        sum["เลขทะเบียน"] = " ";
        console.log(sum);
        res_pdf.push(...dataLast, sum);
      }
      console.log(res_pdf);

      // res_pdf = res_pdf.map((el, index, arr) => {
      //   console.log(arr.length - 1);
      //   console.log(index);
      //   if (arr.length - 1 === index) {
      //   } else {
      //     el['วันที่รับเข้า'] = `${parseInt(el['วันที่รับเข้า'].split('-')[2])} ${this.month[parseInt(el['วันที่รับเข้า'].split('-')[1])]} ${
      //       Number(el['วันที่รับเข้า'].split('-')[0]) + 543
      //     }`;
      //   }
      //   return el;

      // });

      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [10, 70, 10, 40],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "รายงานผลการตรวจสอบวัสดุเเยกตามกลุ่มทรัพย์สิน",
        },
        header: [
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
            margin: [0, 15, 0, 0],
          },
          {
            text: `รายงานผลการตรวจสอบทรัพย์สิน ประจำปีงบประมาณ ${Number(this.date.endDate.split("-")[0]) + 543}`,
            style: "header",
            margin: [0, 0, 0, 0],
          },
          {
            text: `การรับ-จ่าย ทรัพย์สิน ตั้งเเต่วันที่ ${Number(this.date.startDate.split("-")[2])} ${this.month[Number(this.date.startDate.split("-")[1])]
              } ${Number(this.date.startDate.split("-")[0]) + 543} - ${Number(this.date.endDate.split("-")[2])} ${this.month[Number(this.date.endDate.split("-")[1])]
              } ${Number(this.date.endDate.split("-")[0]) + 543}`,
            style: "header",
          },

          {
            text: `กลุ่ม ${this.equipmentGroup.e_gid_e_gname}`,
            style: "header",
            margin: [0, 0, 0, 5],
          },
        ],
        footer: {
          columns: [
            {
              text: `(ลงชื่อ)...................................................................(ประธานกรรมการ)
              (${this.inspector[0]?.inspector1 === undefined || this.inspector[0]?.inspector1 === ""
                  ? "..................................................................."
                  : this.inspector[0]?.inspector1
                })
              ตำแหน่ง ${this.inspector[0]?.inspector_pos1 === undefined || this.inspector[0]?.inspector_pos1 === ""
                  ? "..................................................................."
                  : this.inspector[0]?.inspector_pos1
                }
              `,
              argin: [0, 20, 0, 0],
              style: "text",
              alignment: "center",
            },
            {
              text: `(ลงชื่อ)...................................................................(กรรมการ)
              (${this.inspector[0]?.inspector2 === undefined || this.inspector[0]?.inspector2 === ""
                  ? "..................................................................."
                  : this.inspector[0]?.inspector2
                })
              ตำแหน่ง ${this.inspector[0]?.inspector_pos2 === undefined || this.inspector[0]?.inspector_pos2 === ""
                  ? "..................................................................."
                  : this.inspector[0]?.inspector_pos2
                }`,
              argin: [0, 20, 0, 0],
              style: "text",
              alignment: "center",
            },
            {
              text: `(ลงชื่อ)...................................................................(กรรมการเเละเลขานุการ)
              (${this.inspector[0]?.inspector3 === undefined || this.inspector[0]?.inspector3 === ""
                  ? "..................................................................."
                  : this.inspector[0]?.inspector3
                })
              ตำแหน่ง ${this.inspector[0]?.inspector_pos3 === undefined || this.inspector[0]?.inspector_pos3 === ""
                  ? "..................................................................."
                  : this.inspector[0]?.inspector_pos3
                }`,
              argin: [0, 20, 0, 0],
              style: "text",
              alignment: "center",
            },
          ],
        },
        content: [
          {
            style: "tableHeader",
            table: {
              headerRows: 3,
              keepWithHeaderRows: 2,
              widths: [
                "auto",
                "auto",
                "auto",
                "auto",
                "*",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
              ],
              body: [
                [
                  {
                    text: "ลำ ดับ",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "วัน-เดือน-ปี",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "เลขทะเบียน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "รหัสทรัพย์สิน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "รายการ",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "หน่วย นับ",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "ราคา/หน่วย",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "จำ นวน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "มูลค่ารวม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "อายุ ใช้งาน",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "อัตตรา ค่า เสื่อม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "ค่าเสื่อม ประจำปี",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "ค่าเสื่อม ราคาสะสม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "มูลค่า สุทธิ",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },

                  {
                    text: "สภาพ",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},

                  {
                    text: "สถานที่/ผู้รับผิดชอบ (เดิม)",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                  {
                    text: "สถานที่/ผู้รับผิดชอบ (ใหม่)",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 3,
                  },
                ],
                [
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "ใช้ได้",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ใช้ ไม่ได้",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "ซ่อม",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "จำหน่าย",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                ],
                [
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: "",
                    style: "tableHeader",
                    alignment: "center",
                  },
                ],

                ...res_pdf.map((res, index) => [
                  { text: `${res.เลขทะเบียน == " " ? "" : index + 1}`, style: "text_center" },
                  { text: res.วันที่รับเข้า, style: "text_left" },
                  { text: res.เลขทะเบียน, style: "text_left" },
                  { text: res.หมายเลขทรัพย์สิน, style: "text_left" },
                  { text: res.ชื่อทรัพย์สิน, style: "text_left" },
                  // { text: res.รายละเอียด, style: 'text_center' },
                  { text: res.หน่วยนับ, style: "text_center" },
                  {
                    text:
                      Number(res.ราคาต่อหน่วย)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") == 0.0
                        ? " "
                        : Number(res.ราคาต่อหน่วย)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                    style: "text_right",
                  },
                  { text: res.จำนวน, style: "text_right" },
                  {
                    text: Number(res.มูลค่ารวม)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                    style: "text_right",
                  },
                  { text: res.อายุการใช้งาน, style: "text_right" },
                  {
                    text: res.อัตราค่าเสื่อมราคาต่อปี ? res.อัตราค่าเสื่อมราคาต่อปี : " ",
                    style: "text_right",
                  },
                  {
                    text: res.ค่าเสื่อมราคาประจำปี
                      ? Number(res.ค่าเสื่อมราคาประจำปี)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      : " ",
                    style: "text_right",
                  },
                  {
                    text: Number(res.ค่าเสื่อมราคาสะสม)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                    style: "text_right",
                  },
                  {
                    text: Number(res.มูลค่าสุทธิ)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                    style: "text_right",
                  },
                  { text: res.ใช้ได้, style: "text_center" },
                  { text: res.ใช้ไม่ได้, style: "text_center" },
                  { text: res.ซ่อม, style: "text_center" },
                  { text: res.จำหน่าย, style: "text_center" },
                  { text: res.สถานที่เก่า, style: "text_left" },
                  { text: res.สถานที่ใหม่, style: "text_left" },
                ]),
              ],
            },
          },
        ],
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 0, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 7,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 0, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            bold: false,
            fontSize: 6,
          },
          text_right: {
            alignment: "right",
            fontSize: 6,
          },
          text_left: {
            alignment: "left",
            fontSize: 6,
          },
          text: {
            fontSize: 7,
          },
        },
      };
      this.load_pdf = false;
      pdfMake
        .createPdf(docDefinition)
        .download(`รายงานผลการตรวจสอบวัสดุเเยกตามกลุ่มทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
    },
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      console.log(val);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
            this.date.startDate = dete_val;
            break;
          case "date2":
            this.date.endDate = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: `รายงานผลการตรวจสอบวัสดุเเยกตามกลุ่มทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`,
        columns: [
          {
            title: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: `รายงานผลการตรวจสอบทรัพย์สิน ประจำปีงบประมาณ ${Number(this.date.endDate.split("-")[0]) + 543}`,
                headerCellOptions: { textAlign: "center" },
                children: [
                  {
                    title: `การรับ-จ่าย ทรัพย์สิน ตั้งเเต่วันที่ ${Number(this.date.startDate.split("-")[2])} ${this.month[Number(this.date.startDate.split("-")[1])]
                      } ${Number(this.date.startDate.split("-")[0]) + 543} - ${Number(this.date.endDate.split("-")[2])} ${this.month[Number(this.date.endDate.split("-")[1])]
                      } ${Number(this.date.endDate.split("-")[0]) + 543}`,
                    headerCellOptions: { textAlign: "center" },
                    children: [
                      {
                        title: `กลุ่มทรัพย์สิน ${this.equipmentGroup.e_gname}`,
                        headerCellOptions: { textAlign: "center" },
                        children: [
                          {
                            title: "วันที่-เดือน-ปี",
                            field: "วันที่รับเข้า",
                          },
                          {
                            title: "เลขทะเบียน",
                            field: "เลขทะเบียน",
                          },
                          {
                            title: "รหัสทรัพย์สิน",
                            field: "หมายเลขทรัพย์สิน",
                          },

                          {
                            title: "ชื่อทรัพย์สิน",
                            field: "ชื่อทรัพย์สิน",
                          },

                          {
                            title: "หน่วยนับ",
                            field: "หน่วยนับ",
                          },
                          {
                            title: "ราคาต่อหน่วย",
                            field: "ราคาต่อหน่วย",
                          },
                          {
                            title: "จำนวน",
                            field: "จำนวน",
                          },
                          {
                            title: "อายุการใช้งาน",
                            field: "อายุการใช้งาน",
                          },
                          {
                            title: "อัตราค่าเสื่อมราคา",
                            field: "อัตราค่าเสื่อมราคาต่อปี",
                          },
                          {
                            title: "ค่าเสื่อมประจำปี",
                            field: "ค่าเสื่อมราคาประจำปี",
                          },
                          {
                            title: "ค่าเสื่อมราคาสะสม",
                            field: "ค่าเสื่อมราคาสะสม",
                          },
                          {
                            title: "มูลค่าสุทธิ",
                            field: "มูลค่าสุทธิ",
                          },
                          {
                            title: "สภาพ",
                            headerCellOptions: { textAlign: "center" },
                            children: [
                              {
                                title: "ใช้ได้",
                                field: "ใช้ได้",
                              },
                              {
                                title: "ใช้ไม่ได้",
                                field: "ใช้ไม่ได้",
                              },
                              {
                                title: "ซ่อม",
                                field: "ซ่อม",
                              },
                              {
                                title: "จำหน่าย",
                                field: "จำหน่าย",
                              },
                            ],
                          },

                          {
                            title: "สถานที่/เจ้าหน้าที่(เดิม)",
                            field: "สถานที่เก่า",
                          },
                          {
                            title: "สถานที่/เจ้าหน้าที่(ใหม่)",
                            field: "สถานที่ใหม่",
                          },
                        ],
                      },
                      {},
                    ],
                  },
                  {},
                ],
              },
              {},
            ],
          },
        ],
      });
    },
    perPageChanged(value) {
      console.log(value);
      this.pageLength = value.currentPerPage;
      this.get_table_report();
    },
    pageChanged(value) {
      console.log(value);
      this.currentPage = value.currentPage;
      this.get_table_report();
    },
    async get_table_report() {
      if (this.equipmentGroup === null) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `กรุณาเลือกกลุ่มทรัพย์สิน`,
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      // if (this.selected === null) {
      //   Swal.fire({
      //     position: "center",
      //     icon: "error",
      //     title: `กรุณาเลือกปีงบประมาณ`,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   return;
      // }
      this.show = true;
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      // const startDate = `${this.selected - 544}-10-01`;
      // const endDate = `${this.selected - 543}-09-30`;
      // this.date.startDate = `${this.selected - 544}-10-01`;
      // this.date.endDate = `${this.selected - 543}-09-30`;
      const url1 = `${API}calculateAnnualDepreciation?startDate=${this.date.startDate}&endDate=${this.date.endDate}&e_gid=${this.equipmentGroup.e_gid}&_page=${this.currentPage}&_limit=${this.pageLength}`;
      console.log(url1);
      const data = await axios.get(url1, header);
      console.log(data);
      const { dataLast, sum, total } = data.data.message;

      let res = [];
      res.push(...dataLast, sum);
      this.rows = res.map((el, index, arr) => {
        console.log(arr.length - 1);
        console.log(index);
        if (arr.length - 1 === index) {
        } else {
          el["วันที่รับเข้า"] = `${parseInt(el["วันที่รับเข้า"].split("-")[2])} ${this.month[parseInt(el["วันที่รับเข้า"].split("-")[1])]
            } ${Number(el["วันที่รับเข้า"].split("-")[0]) + 543}`;
        }
        return el;
      });
      this.total = total;

      console.log(this.rows);
      this.show = false;
    },

    formatFn(value) {
      return Number(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    cc_report() {
      this.rows = [];
      this.date.startDate = "";
      this.date.endDate = "";
      this.equipmentGroup = null;
    },
    async equipment_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}equipmentGroups?_page=1&_limit=100`;
      const res = await axios.get(url, header);

      this.equipmentGroups = res.data.message.data.map((element) => ({
        ...element,
        e_gid_e_gname: `${element.e_gid} - ${element.e_gname}`,
      }));
    },
    async workgroups_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}workgroups?_page=1&_limit=100&_sort=1`;
      const res = await axios.get(url, header);
      this.workgroups = res.data.message.data.map((element) => ({
        ...element,
        work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
      }));
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
